.root {
  display: flex;
  gap: 40px;
  height: 960px;
  overflow: hidden;

  .block,
  .blocksm {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .blocksm {
      flex-direction: row;
    }
  }
}
