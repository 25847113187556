.root {
  width: 100%;
  height: 120px;
  background-color: #070b16;
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  justify-content: space-around;
  align-items: center;
  padding: 8px 16px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .link {
    cursor: pointer;
    color: #2d63ff;
    font-weight: 600;

    &:hover {
      color: #567ffa;
      text-decoration: underline;
    }

    &:active {
      color: #0040f0;
      text-decoration: underline;
    }
  }
}
.line {
  display: flex;
  gap: 8px;
}
.mail {
  text-decoration: underline;
}
