.root {
  display: flex;
  background: url('images/map.png') no-repeat right;
  height: 1080px;
  position: relative;

  .video {
    background: url('images/video.png') no-repeat right;
    position: absolute;
    width: 814px;
    height: 458px;
    left: 200px;
    top: 200px;
  }

  .text {
    position: absolute;
    width: 550px;
    height: 300px;
    left: 235px;
    top: 700px;

    display: flex;
    flex-direction: column;
    gap: 64px;

    .block {
      display: flex;
      gap: 16px;
      align-items: center;

      .number {
        width: 192px;
        font-family: 'Unbounded';
        font-weight: 800;
        font-size: 108px;
        line-height: 133px;
      }

      .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;

        .color {
          color: #00ceb5;
        }
      }
    }
  }
}
