.root {
  padding: 64px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 128px;
  height: 960px;

  .line {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 136px;

    .cost {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    .price {
      font-family: 'Unbounded';
      font-weight: 800;
      font-size: 94px;
      line-height: 116px;
    }

    .hint {
      color: #758192;
    }
  }

  .block,
  .blocksm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;

    .logo {
      width: 41px;
      aspect-ratio: 1;
    }

    .title {
      font-family: 'Unbounded';
      font-weight: 800;
      font-size: 30px;
      line-height: 37px;
    }

    .text {
      color: #758192;
    }
  }

  .blocksm {
    align-items: center;
  }
}
