.header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  padding: 64px;
  z-index: 10;
  box-sizing: border-box;

  .consult {
    display: flex;
    gap: 8px;
    font-size: 12;
    font-weight: 800;
    cursor: pointer;

    &:hover {
      color: #567ffa;
    }
  }

  .logo {
    margin-left: 64px;
  }
}

@media (min-width: 768px) {
  .bg {
    background-size: auto;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #fff;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}
