.root {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 960px;
  gap: 82px;

  .header {
    font-family: 'Unbounded';
    font-weight: 700;
    font-size: 35px;
    line-height: 43px;
  }

  .content {
    display: flex;
    gap: 20px;

    .left,
    .right {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .block {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 36px;
        width: 580px;
        height: 220px;
        border: 2px solid #172837;
        box-sizing: border-box;

        &.glow1 {
          background: url('./images/glow1.png') no-repeat right;
        }

        &.glow2 {
          background: url('./images/glow2.png') no-repeat;
        }

        .title {
          font-family: 'Unbounded';
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
        }

        .text {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #758192;
        }
      }

      .blocklg {
        display: flex;
        flex-direction: column;
        width: 580px;
        height: 460px;
        border: 2px solid #172837;
        gap: 12px;
        padding: 36px;
        box-sizing: border-box;
        background: url('./images/bg.png') no-repeat,
          linear-gradient(122.92deg, #321aa2 0%, #204ad2 98.27%);

        .title {
          font-family: 'Unbounded';
          font-weight: 900;
          font-size: 40px;
          line-height: 50px;
        }

        .text,
        .list {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          width: 416px;
        }

        .list {
          display: flex;
          flex-direction: column;
          gap: 36px;
          list-style: none;
          font-size: 12px;

          li::before {
            content: '\25CF'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: #00cad3; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
          }
        }
      }
    }
  }
}
