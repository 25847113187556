.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 120px;
  background: url('./images/glow.png') no-repeat 100% 30%;

  .header {
    display: flex;
    flex-direction: column;
    .title {
      font-family: 'Unbounded';
      font-weight: 700;
      font-size: 35px;
      line-height: 43px;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #758192;
    }
  }

  .content {
    display: flex;

    .formContainer {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .title {
        font-family: 'Montserrat';
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
      }

      .form {
        width: 680px;
        height: 550px;
        background: #070b16 url('./images/formbg.png') no-repeat;
        padding: 50px;
        display: flex;
        flex-direction: column;
        gap: 64px;

        .question {
          font-family: 'Montserrat';
          font-weight: 700;
          font-size: 30px;
          line-height: 37px;
        }

        .border {
          border-top: 3px solid #204ad2;
          width: 564px;
        }

        input {
          border: 2px solid;
          border-image-slice: 1;
          border-image-source: linear-gradient(
            180deg,
            #00cfb3 0%,
            #00cad3 100%
          );
          background-color: #172837;
          margin-top: 10%;
          width: 500px;
          color: #fff;
          padding: 8px;
          font-size: 16px;
          font-weight: bold;
          border-radius: 4px;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          outline: none;
        }

        input:focus {
          border-color: #204ad2;
          box-shadow: 0px 0px 8px rgba(32, 74, 210, 0.5);
        }

        .btnContainer {
          display: flex;
          align-self: flex-end;
          margin-top: auto;
        }
      }
    }
  }
}
