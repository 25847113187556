.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #00cfb3 0%, #00cad3 100%);
  clip-path: polygon(
    20px 0%,
    100% 0%,
    100% calc(100% - 60px),
    calc(102% - 30px) 100%,
    0% 100%,
    0% 60px
  );
  box-shadow: none;
  cursor: pointer;
  width: 300px;
  height: 60px;
  color: #0a1f32;
  border: none;
  outline: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 800;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  &.small {
    width: 165px;
    height: 45px;
  }
}

.button:hover {
  background: linear-gradient(180deg, #321aa2 0%, #1569f0 100%);
  box-shadow: 0px 0px 30px rgba(32, 74, 210, 0.55);
  color: #fff;
}
