.root {
  height: 1080px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 64px;
  gap: 32px;

  .headerbig {
    font-family: 'Unbounded';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 43px;
    align-self: flex-start;
    padding-left: 250px;
  }

  .line {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
    margin-right: 150px;

    .point {
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;

      .dot {
        background: linear-gradient(180deg, #00cfb3 0%, #00cad3 100%);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        color: #0a1f32;
      }

      .lastDot {
        color: white;
        background: linear-gradient(180deg, #321aa2 0%, #204ad2 100%);
      }

      .block {
        position: absolute;
        top: 55px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 300px;
        gap: 8px;

        .header {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 24px;
        }
        .title {
          color: #2e7eff;
        }
        .hint {
          color: #758192;
        }
      }
    }
    .dotLine {
      border-top: 1px dashed #00cad3;
      width: 300px;
    }
    .rightDotLine {
      border: 1px dashed #00cad3;
      border-left: none;
      width: 300px;
      height: 370px;
      position: absolute;
      right: 0;
      transform: translateX(100%) translateY(50%);
    }
    .leftDotLine {
      border: 1px dashed #00cad3;
      border-right: none;
      width: 100px;
      height: 370px;
      position: absolute;
      left: 0;
      transform: translateX(-100%) translateY(50%);
    }
  }

  .second {
    margin-top: 290px;
  }
  .third {
    margin-top: 290px;
    margin-left: 260px;
    align-self: normal;
  }
}
