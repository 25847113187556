.root {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('./images/glow.png') no-repeat bottom left;
  height: 960px;

  .content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 120px;

    .details {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    .block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 400px;
      gap: 150px;

      .header {
        font-family: 'Unbounded';
        font-weight: 800;
        font-size: 42px;
        line-height: 52px;
      }

      .text {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
      }

      .phone {
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
}
