.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../../public/images/main/bg.png');
  height: 100vh;
  gap: 96px;
  padding: 200px 0 100px;

  .text {
    font-family: Unbounded;
    font-size: 53px;
    font-weight: 700;
    line-height: 62px;
    text-align: left;
    max-width: 800px;
  }

  .points {
    display: flex;
    justify-content: space-between;
    gap: 128px;
    align-items: center;

    .point {
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: 324px;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
    }
  }
}
